.contact-section {
  padding: 7em 0;
}

.wrapper {
  width: 100%;
  -webkit-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
}

.contact-wrap {
  background: rgba(127, 39, 156, 1);
  background: -moz-linear-gradient(
    -45deg,
    rgba(127, 39, 156, 1) 0%,
    rgba(46, 39, 157, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, rgba(127, 39, 156, 1)),
    color-stop(100%, rgba(46, 39, 157, 1))
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(127, 39, 156, 1) 0%,
    rgba(46, 39, 157, 1) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgba(127, 39, 156, 1) 0%,
    rgba(46, 39, 157, 1) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba(127, 39, 156, 1) 0%,
    rgba(46, 39, 157, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(127, 39, 156, 1) 0%,
    rgba(46, 39, 157, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7f279c', endColorstr='#2e279d', GradientType=1 );
  h3 {
    color: #fff;
  }
}

h3 {
  color: white;
}

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.contact-us-form-control {
  height: 62px !important;
  background: transparent !important;
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 16px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5) !important;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5) !important;
  }
  &:-ms-input-placeholder {
    /* IE 0+ */
    color: rgba(255, 255, 255, 0.5) !important;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.5) !important;
  }
  &:focus,
  &:active {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.5) !important;
  }
}

textarea.contact-us-form-control {
  height: inherit !important;
  margin-top: 5px !important;
}

.contactForm {
  .contact-us-form-control {
    border: none !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    padding: 0 !important;
  }
}

.contactus-btn {
  padding: 12px 16px !important;
  cursor: pointer !important;
  border-width: 1px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12) !important;
  -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12) !important;
  position: relative !important;
  margin-top: 20px !important;
}

.contactus-btn-primary {
  background: #d62196 !important;
  border-color: #d62196 !important;
  color: #fff !important;
}

.info-wrap {
  h3 {
    color: #000;
    position: relative;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 60px;
      height: 3px;
      background: #d62196;
      content: '';
    }
  }
  .dbox {
    width: 100%;
    margin-bottom: 25px;
    &:last-child() {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
      span {
        font-weight: 400;
        color: #000;
      }
      a {
        color: rgba(0, 0, 0, 0.3);
      }
    }
    .icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.05);
      span {
        font-size: 20px;
        color: #000;
      }
    }
    .text {
      width: calc(100% - 50px);
    }
  }
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  line-height: 1.5;
  font-weight: 400;
  font-family: 'Poppins', Arial, sans-serif;
  color: #000;
}

a {
  transition: 0.3s all ease;
  color: #d62196;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none !important;
    outline: none !important;
    box-shadow: none;
  }
}

#contactForm {
  .contact-us-form-control {
    font-size: 16px;
  }
}
.contact-us-form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px inherit inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
  color: 'white' !important;
  -webkit-text-fill-color: white !important;
}
