.logo {
  margin: 5px;
}

.btnn-link {
  font-size: 16px !important;
  padding: 10px 20px !important;
  margin: 5px !important;
  border-radius: 25px !important;
  background-color: #054080 !important;
  color: white !important;
  text-align: center !important;
  display: inline-block !important;
  text-decoration: none !important;
}

.btnn-link:hover,
.btnn-link:focus {
  background-color: #0056b3 !important;
  color: white !important;
  text-decoration: none !important;
}

/* .btnn-link:hover {
  text-decoration: underline !important;
  text-decoration-thickness: 2px !important;
  text-underline-offset: 5px !important;
} */

.nav-item:hover {
  color: rgb(103, 82, 243);
  text-decoration: underline !important;
  text-decoration-thickness: 2px !important;
  text-underline-offset: 5px !important;
}

.nav-menu-main {
  padding: 15px !important;
}
.hero-section {
  margin: 100px 5% 5% 5% !important;
}
.custom-width {
  width: 100vw; /* or any width you want */
}
.mt-custom {
  margin-top: 6rem; /* Adjust this value as needed */
}

.custom-bg-color {
  margin-top: 6rem !important;
  background-color: #f1f1f1 !important;
}

.logo {
  cursor: pointer !important;
}

.hero-button {
  width: 200px;
}
.choose-plan-button:hover {
  background-color: #0a509b !important;
  color: white !important;
}
.footer-link {
  color: #1d1f21 !important;
  background-color: transparent !important;
  cursor: pointer !important;
  text-decoration: none !important;
}

.footer-link:hover {
  color: #555557 !important;
}
.footer-link-container {
  margin-top: 5px !important;
}

.sidebar {
  /* position: fixed;  */
  /* width: 20vw;  */
  height: 100vh;
  background-color: #d5070700 !important;
}

.card-hover {
  transition: transform 0.2s;
  background: transparent;
  background-color: transparent !important;
  border: rebeccapurple;
  border-width: 0.25rem !important;
}

.card-hover:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.link-in-bio-url-name {
  font-size: 30px !important;
  background-color: transparent !important;
  cursor: pointer !important;
  text-decoration: none !important;
}

.card-hover-builder {
  transition: transform 0.2s;
  background: transparent;
  background-color: transparent !important;
  border: rebeccapurple;
  border-width: 0.25rem !important;
}

.text-black {
  color: black !important;
}

.text-white {
  color: white !important;
}
.hoverEffect:hover {
  transform: scale(1.1) !important;
}
.chart-container {
  height: 50vh !important;
  min-width: 50vw !important;
}

@media (max-width: 576px) {
  .chart-container {
    height: 25vh !important;
    min-width: 75vw !important;
  }
}
/* styles.css */
.no-underline {
  text-decoration: none !important;
  color: inherit; /* Ensure the link color remains consistent */
}

.no-underline:hover,
.no-underline:focus,
.no-underline:active {
  text-decoration: none !important;
  color: inherit; /* Ensure the link color remains consistent on hover, focus, and active states */
}

.sidebar-item {
  transition: background-color 0.3s ease, color 0.3s ease !important;
}

.sidebar-item:hover {
  background-color: #054080 !important;
  color: white !important;
  border-radius: 10px !important;
}

.copy-icon .tooltip {
  top: calc(100% - 30px); /* Adjust as needed */
  left: 0;
}

.cards-container {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-around !important;
}

.cards-container .col-sm-3 {
  display: flex !important;
  flex: 1 !important;

  max-width: 250px !important;
  min-width: 450px !important;
  flex-basis: auto !important;
}
